import React, { Component } from "react"
import { graphql } from 'gatsby'
import ProductGrid from '../components/productGrid'
import AppBar from '../components/appBar'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  root: {
    width: '100%',
    maxWidth: 500,
  },
});



class Indexpage extends Component {

  render() {
    const {
      data: { homepage },
    } = this.props
    return (
      <React.Fragment>
        <AppBar />
        <div className={useStyles.root}>
          <Typography variant="h4" component="h3" gutterBottom>
            {homepage.data.title.text}
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            {homepage.data.content.text}
          </Typography>
          <Typography variant="h6" component="h3" gutterBottom>
            {homepage.data.footer.text}
          </Typography>
        </div>

        <ProductGrid />
      </React.Fragment>
    )

  }
}


export default Indexpage

export const pageQuery = graphql`
  query IndexQuery {
    homepage: prismicHomepage {
      data {
        title {
          text
        }
        content {
          text
        }
        footer {
          text
        }        
      }
    }
  }
  
`